import logo from './logo.svg';
import './App.css';
import HelloButton from './HelloButton';

function App() {
  return (
      <div className="App">
          <h1>This is My First React Application</h1>
        <HelloButton/>
    </div>
  );
}

export default App;
