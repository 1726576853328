import React, { useState } from 'react';

const HelloButton = () => {
	const [message, setMessage] = useState('');
	const handleClick = () => {
		setMessage('Helo World');
	};

	return (
		<div>
			<button onClick={handleClick}> Click Me</button>
			<p>{message}</p>
		</div>
	);
};

export default HelloButton;